<template>
  <app-blank>
    <div class="h-screen flex w-full bg-img">
      <div class="vx-col mx-auto self-center">
        <vx-card>
          <div class="full-page-bg-color">
            <div class="vx-row">
              <div class="vx-col sm:w-full md:w-full lg:w-full mx-auto self-center bg-white">
                <div class="vx-logo flex items-center">
                  <img :src="require('@/assets/images/logo/logo.png')" class="w-10 mr-2">
                  <span class="vx-logo-text text-primary">Shoes and Care</span>
                </div>
                <div class="p-1">
                  <div class="vx-card__title mb-8" />
                  <vs-input
                    icon="icon icon-user"
                    icon-pack="feather"
                    placeholder="Username"
                    v-model="credential.username"
                    class="w-full mb-6 no-icon-border"
                  />
                  <vs-input
                    type="password"
                    icon="icon icon-lock"
                    icon-pack="feather"
                    placeholder="Password"
                    v-model="credential.password"
                    class="w-full mb-4 no-icon-border"
                    @keyup.enter="login"
                  />
                  <div class="flex flex-wrap justify-between my-5">
                    <vs-checkbox class="mb-3" v-model="credential.keep">
                      Remember Me
                    </vs-checkbox>
                  </div>
                  <vs-button
                    id="login-button"
                    class="vs-con-loading__container w-full"
                    @click="login"
                    type="relief"
                    vslor="primary"
                  >
                    Login
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </app-blank>
</template>

<script>
import blank from '@/layouts/blank/Blank.vue';
import AuthRepo from '@/repositories/auth_repo';

export default {
  data: () => ({
    credential: {
      username: null,
      password: null,
      keep: true
    },
    loading: false
  }),
  components: {
    appBlank: blank
  },
  methods: {
    async login() {
      if (this.credential.username && this.credential.password) {
        this.loading = true;
        try {
          const { data: content } = await AuthRepo.login(this.credential);
          this.$store.dispatch('login', content);
        } catch (error) {
          this.$vs.notify({
            title: 'Whoops',
            text: 'credential not match',
            color: 'danger',
            position: 'bottom-center'
          });
          this.credential.password = null;
          this.loading = false;
        }
      }
    }
  },
  watch: {
    loading(isLoading) {
      if (isLoading) {
        this.$vs.loading({
          background: 'primary',
          color: '#fff',
          container: '#login-button',
          scale: 0.45
        });
      } else {
        this.$vs.loading.close('#login-button > .con-vs-loading');
      }
    }
  }
};
</script>
